$headline_font: "Poppins", Helvetica, sans-serif;

// demo styles
//
//

$pink: #f6175c;
$blue: #7ac2ce;

body {
    // background-color: #fefcfd;
}

.lb_gift {
    /*border: 2px solid red;*/
    margin: 10px auto;
    max-width: 600px;
    position: relative;
}
.lb_gift p {
    display: inline-block;
    /*float: left;*/
    width: calc(100% - 175px);
    padding: 0 0 0 15px;
}
.lb_gift_btn {
    display: inline-block;
    right: 25px;
    top: 15px;
    position: absolute;
    /*float: right;*/
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    font-family: $headline_font;
}

h2 {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 46px;
    color: $pink;
    text-transform: uppercase;
}

h3 {
    font-size: 24px;
    letter-spacing: 1px;
}

p {
    font-size: 16px;
    line-height: 24px;
}

ul {
    margin: 0px;
    padding: 0px;
}

ul li {
    list-style-type: none;
    margin: 5px 0;
}

a {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 200;
    color: #000;
}

ul li a:hover {
    color: $pink;
}

.w_section {
    margin: 150px auto 25px;
    padding: 0px 15px;
    max-width: 767px;
    position: relative;
}

// ##########################################
// answers

.faq_answer_info {
    // border-top: 2px dotted #ddd;
    // padding: 12px 0 12px 25px;
    position: relative;
}

.linkedin_logo {
    width: 20px;
    position: relative;
    top: 3px;
    // margin: 0 0 0 10px;
}

.info_bar_wrapper {
    background-color: #fff;
    position: fixed;
    top: 0px;
    z-index: 100;
    width: 100%;
}

.info_bar_wrapper h1 a {
    margin: 10px 0;
    font-size: 27px;
    line-height: 45px;
    float: left;
    text-decoration: none;
    font-weight: 600;
}

.info_bar {
    margin: 0px auto 0px;
    max-width: 900px;
    padding: 0 20px;
}

.w_info {
    margin: 0 0 50px 0;
    h3 {
        color: #000;
        margin: 0px;
        text-transform: uppercase;
        letter-spacing: -0.5px;
        padding: 20px 0 0 0;
        font-size: 21px;
    }
    h3 ~ p {
        padding: 0px;
        margin: 10px 0;
    }
}

.contact_info {
    float: right;
    padding: 10px 0;
}

.contact_info a {
    position: relavie;
    text-decoration: none;
    margin: 0 0 0 10px;
    font-weight: 600;
}

.project_grid {
    // background-color: yellow;
}

.project_grid a {
    text-decoration: none;
    width: 98%;
    float: left;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 1%;
    margin-right: 1%;

    @include breakpoint(525px) {
        width: 46%;
        margin-left: 2%;
        margin-right: 2%;
    }

    @include breakpoint(767px) {
        width: 30%;
        margin-left: 1%;
        margin-right: 1%;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.project_grid img,
.work_page_grid img {
    width: 100%;
}

.project_grid:after,
.work_page_grid:after,
.project_page_grid.no_col:after {
    content: "";
    display: table;
    clear: both;
}

#work_page img.main_img {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.embed-container {
    position: relative;
    padding-bottom: 56.2%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video_container {
    max-width: 1024px;
    margin: 50px auto 0;
}

.work_page_grid a {
    text-decoration: none;
    width: 98%;
    float: left;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 1%;
    margin-right: 1%;

    @include breakpoint(525px) {
        width: 46%;
        margin-left: 2%;
        margin-right: 2%;
    }
}

.project_page_grid img {
    margin: 10px 0;
}

.work_page_grid a:nth-child(2n + 1),
.project_page_grid.no_col img:nth-child(2n + 1) {
    margin-left: 1%;
    margin-right: 1%;

    @include breakpoint(525px) {
        margin-left: 0%;
    }
}

.work_page_grid a:nth-child(2n + 0),
.project_page_grid.no_col img:nth-child(2n + 0) {
    margin-left: 1%;
    margin-right: 1%;

    @include breakpoint(525px) {
        margin-left: 6%;
        margin-right: 0;
    }
}

.project_page_grid .col,
.project_page_grid.no_col img {
    text-decoration: none;
    width: 98%;
    float: left;
    margin-left: 1%;
    margin-right: 1%;
    float: left;

    @include breakpoint(525px) {
        width: 46%;
        margin-left: 2%;
        margin-right: 2%;
    }
}

.project_page_grid.no_col {
    margin-bottom: 20px;
}

.project_page_grid.no_col img {
    margin-top: 20px;
}

.project_page_grid .col img {
    text-decoration: none;
    width: 100%;
}

.project_page_grid .one_col {
    display: block;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 20px;
}

.project_page_grid .one_col img {
    width: 100%;
}

.project_page_grid .col img {
    margin-top: 20px;
    border: 2px solid #e7e7e7;
    box-sizing: border-box;
}

.portfolio h4 {
    margin: 60px 0 0 0;
}

.portfolio h3 {
    font-size: 24px;
    letter-spacing: 1px;
    margin: 60px 0 0 0;
}

.portfolio p {
    font-size: 16px;
    line-height: 23px;
}

.frontpage_loader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    padding: 5px 0 25px;
    background: #f6175c;
    z-index: 1000;
    box-sizing: border-box;
    opacity: 0;
}

.frontpage_loader h2 {
    font-size: 11.25vw;
    line-height: 9vw;
    margin: 0px;
    color: #fff;
    overflow: hidden;
    background: #f6175c;
    padding: 0 0 0 5px;

    @include breakpoint(1024px) {
        line-height: 9vw;
    }
}

.frontpage_loader.disable_loader {
    display: none;
}

.frontpage_loader.enable_loader {
    animation: fade_out_loader 4s 1;
}

@keyframes fade_out_loader {
    0% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
