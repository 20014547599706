html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td, th {
  padding: 0;
}

.lb_gift {
  max-width: 600px;
  margin: 10px auto;
  position: relative;
}

.lb_gift p {
  width: calc(100% - 175px);
  padding: 0 0 0 15px;
  display: inline-block;
}

.lb_gift_btn {
  display: inline-block;
  position: absolute;
  top: 15px;
  right: 25px;
}

h1, h2, h3, h4, h5, h6, p, span {
  font-family: Poppins, Helvetica, sans-serif;
}

h2 {
  letter-spacing: -1px;
  color: #f6175c;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 46px;
}

h3 {
  letter-spacing: 1px;
  font-size: 24px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  margin: 5px 0;
  list-style-type: none;
}

a {
  color: #000;
  font-size: 16px;
  font-weight: 200;
  text-decoration: underline;
}

ul li a:hover {
  color: #f6175c;
}

.w_section {
  max-width: 767px;
  margin: 150px auto 25px;
  padding: 0 15px;
  position: relative;
}

.faq_answer_info {
  position: relative;
}

.linkedin_logo {
  width: 20px;
  position: relative;
  top: 3px;
}

.info_bar_wrapper {
  z-index: 100;
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
}

.info_bar_wrapper h1 a {
  float: left;
  margin: 10px 0;
  font-size: 27px;
  font-weight: 600;
  line-height: 45px;
  text-decoration: none;
}

.info_bar {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
}

.w_info {
  margin: 0 0 50px;
}

.w_info h3 {
  color: #000;
  text-transform: uppercase;
  letter-spacing: -.5px;
  margin: 0;
  padding: 20px 0 0;
  font-size: 21px;
}

.w_info h3 ~ p {
  margin: 10px 0;
  padding: 0;
}

.contact_info {
  float: right;
  padding: 10px 0;
}

.contact_info a {
  position: relavie;
  margin: 0 0 0 10px;
  font-weight: 600;
  text-decoration: none;
}

.project_grid a {
  width: 98%;
  float: left;
  margin: 20px 1% 10px;
  text-decoration: none;
}

@media (min-width: 525px) {
  .project_grid a {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media (min-width: 767px) {
  .project_grid a {
    width: 30%;
    margin: 5px 1%;
  }
}

.project_grid img, .work_page_grid img {
  width: 100%;
}

.project_grid:after, .work_page_grid:after, .project_page_grid.no_col:after {
  content: "";
  clear: both;
  display: table;
}

#work_page img.main_img {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.embed-container {
  height: 0;
  max-width: 100%;
  padding-bottom: 56.2%;
  position: relative;
  overflow: hidden;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video_container {
  max-width: 1024px;
  margin: 50px auto 0;
}

.work_page_grid a {
  width: 98%;
  float: left;
  margin: 20px 1% 10px;
  text-decoration: none;
}

@media (min-width: 525px) {
  .work_page_grid a {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.project_page_grid img {
  margin: 10px 0;
}

.work_page_grid a:nth-child(2n+1), .project_page_grid.no_col img:nth-child(2n+1) {
  margin-left: 1%;
  margin-right: 1%;
}

@media (min-width: 525px) {
  .work_page_grid a:nth-child(2n+1), .project_page_grid.no_col img:nth-child(2n+1) {
    margin-left: 0%;
  }
}

.work_page_grid a:nth-child(2n), .project_page_grid.no_col img:nth-child(2n) {
  margin-left: 1%;
  margin-right: 1%;
}

@media (min-width: 525px) {
  .work_page_grid a:nth-child(2n), .project_page_grid.no_col img:nth-child(2n) {
    margin-left: 6%;
    margin-right: 0;
  }
}

.project_page_grid .col, .project_page_grid.no_col img {
  width: 98%;
  float: left;
  float: left;
  margin-left: 1%;
  margin-right: 1%;
  text-decoration: none;
}

@media (min-width: 525px) {
  .project_page_grid .col, .project_page_grid.no_col img {
    width: 46%;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.project_page_grid.no_col {
  margin-bottom: 20px;
}

.project_page_grid.no_col img {
  margin-top: 20px;
}

.project_page_grid .col img {
  width: 100%;
  text-decoration: none;
}

.project_page_grid .one_col {
  width: 98%;
  margin-top: 20px;
  margin-left: 1%;
  margin-right: 1%;
  display: block;
}

.project_page_grid .one_col img {
  width: 100%;
}

.project_page_grid .col img {
  box-sizing: border-box;
  border: 2px solid #e7e7e7;
  margin-top: 20px;
}

.portfolio h4 {
  margin: 60px 0 0;
}

.portfolio h3 {
  letter-spacing: 1px;
  margin: 60px 0 0;
  font-size: 24px;
}

.portfolio p {
  font-size: 16px;
  line-height: 23px;
}

.frontpage_loader {
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  box-sizing: border-box;
  opacity: 0;
  background: #f6175c;
  padding: 5px 0 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.frontpage_loader h2 {
  color: #fff;
  background: #f6175c;
  margin: 0;
  padding: 0 0 0 5px;
  font-size: 11.25vw;
  line-height: 9vw;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .frontpage_loader h2 {
    line-height: 9vw;
  }
}

.frontpage_loader.disable_loader {
  display: none;
}

.frontpage_loader.enable_loader {
  animation: 4s fade_out_loader;
}

@keyframes fade_out_loader {
  0% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*# sourceMappingURL=index.40076094.css.map */
